const aiBaseUrl = process.env.VUE_APP_AI_API_BASE_URL

export default function (api) {
  return {
    // get /web/merchant/{merchantId}/init
    getMerchantInit (id) {
      return api.get(`public/merchant/${id}/base`, {
      })
    },
    getPublicMerchant (id) {
      let basePath = `public/merchant/${id}`
      return api.get(basePath, {
        shouldJWT: false
      })
    },
    getMerchant (id) {
      let basePath = `merchant/${id}`
      return api.get(basePath, {
        shouldJWT: true
      })
    },
    // get modules 
    getModules (id) {
      return api.get(`public/merchant/${id}/modules`)
    },
    // servicelanguages
    getServiceLanguages (id) {
      return api.get(`public/merchant/${id}/servicelanguages`)
    },
    // get facilities
    getFacilities (id) {
      return api.get(`public/merchant/${id}/facilities`)
    },
    // get facilities facilityId
    getFacility (id, facilityId) {
      return api.get(`public/merchant/${id}/facilities/${facilityId}`)
    },
    // get categories
    getCategories (id) {
      return api.get(`public/merchant/${id}/categories`)
    },
    // post v2/discover
    postV2Discover (id, data) {
      return api.post(`public/merchant/${id}/v2/discover`, data)
    },
    // post discover
    postDiscover (id, data, query) {
      return api.post(`public/merchant/${id}/discover`, data,
        {
          params: query
        }
      )
    },
    // post /web/merchant/{merchantId}/food/discover
    postFoodDiscover (id, data, query) {
      return api.post(`public/merchant/${id}/food/discover`, data,
        {
          params: query
        }
      )
    },
    // get /web/merchant/{merchantId}/food/categories
    getFoodCategories (id) {
      return api.get(`public/merchant/${id}/food/categories`)
    },
    // get /web/merchant/{merchantId}/discover/{discoverId}
    getDiscoverDetail (id, discoverId, data) {
      return api.get(`public/merchant/${id}/discover/${discoverId}`, {
        params: data
      })
    },
    // get /web/merchant/{merchantId}/food/discover/{id}
    getFoodDiscoverDetail (id, discoverId, data) {
      return api.get(`public/merchant/${id}/food/discover/${discoverId}`, {
        params: data
      })
    },
    // get /web/v2/merchant/{merchantId}/coupons
    getV2Coupons (id, data) {
      return api.get(`/v2/merchant/${id}/coupons`, {
        params: data,
        shouldJWT: true
      })
    },
    // get /web/public/v2/merchant/{merchantId}/coupons
    getPublicV2Coupons (id, data) {
      return api.get(`/public/v2/merchant/${id}/coupons`, {
        params: data
      })
    },
    // get /web/v2/merchant/{merchantId}/coupons/{couponId}
    getV2CouponDetail (id, couponId, data) {
      return api.get(`/v2/merchant/${id}/coupons/${couponId}`, {
        params: data,
        shouldJWT: true
      })
    },
    // post /web/v2/coupon/{couponId}
    postV2Coupon (couponId, data) {
      return api.post(`/v2/coupon/${couponId}`, data, {
        shouldJWT: true
      })
    },
    // post /web/v2/user/coupon/{couponId}
    postV2UserCoupon (couponId, data) {
      return api.post(`/v2/user/coupon/${couponId}`, data, {
        shouldJWT: true
      })
    },
    // get /web/public/v2/merchant/{merchantId}/coupons/{couponId}
    getPublicV2CouponDetail (id, couponId, data) {
      return api.get(`/public/v2/merchant/${id}/coupons/${couponId}`, {
        params: data
      })
    },
    // post /web/user/merchant/{merchantId}/translationshistory
    postTranslationsHistory (id, data) {
      return api.post(`user/merchant/${id}/translationshistory`, data, {
        shouldJWT: true
      })
    },
    // get /web/user/merchant/{merchantId}/translationshistory
    getTranslationsHistory (id, data) {
      return api.get(`user/merchant/${id}/translationshistory`, {
        params: data,
        shouldJWT: true
      })
    },
    // post /web/public/user/register/sendcode
    postSendCode (data) {
      return api.post(`public/user/register/sendcode`, data)
    },
    // post /web/public/user/register/verifycode
    getVerifycode (data) {
      return api.post(`public/user/register/verifycode`, data)
    },
    // post /web/public/user/register
    postRegister (data) {
      return api.post(`public/user/register`, data)
    },
    // post /web/login
    postLogin (data) {
      return api.post(`login`, data)
    },
    // get /web/v2/user/coupons
    getV2UserCoupons (data) {
      return api.get(`v2/user/coupons`, {
        params: data,
        shouldJWT: true
      })
    },
    // post /web/merchant/{{merchantId}}/message
    postMessage (id, data) {
      return api.post(`merchant/${id}/message`, data, {
        shouldJWT: true
      })
    },
    // post /web/public/merchant/{merchantId}/message
    postPublicMessage (id, data) {
      return api.post(`public/merchant/${id}/message`, data)
    },
    // post /web/user/merchant/{merchantId}/favorite
    postFavorite (id, data) {
      return api.post(`user/merchant/${id}/favorite`, data, {
        shouldJWT: true
      })
    },
    // get /web/user/history/merchants
    getHistoryMerchants (data) {
      return api.get(`user/history/merchants`, {
        params: data,
        shouldJWT: true
      })
    },
    // post /web/public/user/password/sendcode
    postPasswordSendCode (data) {
      return api.post(`public/user/password/sendcode`, data)
    },
    // post /web/public/user/password/verifycode
    postPasswordVerifycode (data) {
      return api.post(`public/user/password/verifycode`, data)
    },
    // post /web/public/user/password
    postPassword (data) {
      return api.post(`public/user/password`, data)
    },
    // get /web/user/profile
    getUserProfile (data) {
      return api.get(`user/profile`, {
        params: data,
        shouldJWT: true
      })
    },
    // get /web/merchant/last
    getMerchantLast (data) {
      return api.get(`merchant/last`, {
        params: data,
        shouldJWT: true
      })
    },
    // put /web/user/profile
    putUserProfile (data) {
      return api.put(`user/profile`, data, {
        shouldJWT: true
      })
    },
    // get /web/user/azure
    getUserAzureSpeech (data) {
      return api.get(`user/azure/speech/token`, {
        params: data,
        shouldJWT: true
      })
    },
    // get /web/public/user/azure/speech/token
    getPublicUserAzureSpeech (data) {
      return api.get(`public/user/azure/speech/token`, {
        params: data
      })
    },
    // get user/azure/translator/token
    getUserAzureTranslator (data) {
      return api.get(`user/azure/translator/token`, {
        params: data,
        shouldJWT: true
      })
    },
    // get /web/public/user/azure/translator/token
    getPublicUserAzureTranslator (data) {
      return api.get(`public/user/azure/translator/token`, {
        params: data
      })
    },
    // post /web/public/log/access
    postPublicLogAccess (data) {
      return api.post(`public/log/access`, data, {
        // 静默请求
        silent: true,
        noLoading: true
      })
    },
    // post /web/log/access
    postLogAccess (data) {
      return api.post(`log/access`, data, {
        silent: true,
        noLoading: true,
        shouldJWT: true
      })
    },
    // get /web/public/merchant/{merchantId}/wifi
    getPublicMerchantWifi (id) {
      return api.get(`public/merchant/${id}/wifi`)
    },
    // get /web/public/contents/{/contentId}
    getPublicContent (id) {
      return api.get(`public/contents/${id}`)
    },
    // get web/public/merchant/{merchantId}/contents 
    getPublicMerchantContents (id) {
      return api.get(`public/merchant/${id}/contents`)
    },
    // get /web/public/merchant/{merchantId}/contents/{contentId}
    getPublicMerchantContent (id, contentId) {
      return api.get(`public/merchant/${id}/contents/${contentId}`)
    },
    // /web/public/merchant/{merchantId}/skifacility/lift GET
    getPublicMerchantSkifacilityLift (id) {
      return api.get(`public/merchant/${id}/skifacility/lift`)
    },
    // /web/public/merchant/{merchantId}/skifacility/current GET
    getPublicMerchantSkifacilityCurrent (id) {
      return api.get(`public/merchant/${id}/skifacility/current`)
    },
    // 
    // /web/public/merchant/{merchantId}/skifacility/course GET
    getPublicMerchantSkifacilityCourse (id) {
      return api.get(`public/merchant/${id}/skifacility/course`)
    },
    // isAzureApi
    // post https://api.cognitive.microsofttranslator.com/detect
    postAzureDetect (data, token, query) {
      return api.post(`https://api.cognitive.microsofttranslator.com/detect`, data, {
        isAzureApi: true,
        params: {
          'api-version': '3.0',
          ...query
        },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
    },
    // post https://api.cognitive.microsofttranslator.com/translate
    postAzureTranslate (data, token, query) {
      return api.post(`https://api.cognitive.microsofttranslator.com/translate`, data, {
        isAzureApi: true,
        params: {
          'api-version': '3.0',
          ...query
        },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
    },
    // isAiApi
    // get AI /merchant/1
    getAIMerchant (id) {
      return api.get(`${aiBaseUrl}/merchant/${id}`, {
        shouldAiJWT: true,
        isAiApi: true,
      })
    },
    getChatHistory (id, data) {
      return api.get(`${aiBaseUrl}/merchant/${id}/chatHistory`, {
        shouldAiJWT: true,
        isAiApi: true,
        params: data,
      })
    },
    getQuery (data, setResponseText) {
      return api.get(`${aiBaseUrl}/query`, {
        shouldAiJWT: true,
        noLoading: true,
        isAiApi: true,
        params: data,
        onDownloadProgress: (progressEvent) => {
          const dataChunk = progressEvent.event.target.response
          setResponseText(dataChunk)
        }
      })
    },
    putQuery (id, data) {
      return api.put(`${aiBaseUrl}/query/${id}`, data, {
        isAiApi: true,
        shouldAiJWT: true,
      })
    },
    getCommonQuestion (data) {
      return api.get(`${aiBaseUrl}/query/commonQuestion`, {
        shouldAiJWT: true,
        isAiApi: true,
        params: data,
      })
    },
  }
}
